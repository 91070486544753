<template>
  <FormModal
    title="Introduce tus datos"
    next-btn-text="Compara ahora"
    show-back-btn
  >
    <template #body>
      <ContactInformationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  components: {
    ContactInformationFormStep,
    FormModal
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Introduce tus datos',
      headDescription: 'Ingrese sus datos para que podamos ponerlo en contacto con los proveedores de paneles solares más adecuados en su región.',
      path: '/solicitudes-de-cotizacion/introduce-tus-datos',
      progressValue: 75,
      checkoutStep: 3
    }
  }
}
</script>
